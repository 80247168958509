/**
 * Note: This Slice is used only within the project and is not made available
 * to editors in Prismic.
 */

import * as React from 'react'
import { GoogleMap, Marker, InfoBox } from '@react-google-maps/api'
import clsx from 'clsx'
import { ArrowRightCircleIcon } from '@heroicons/react/20/solid'

import { PageTemplateEnhancerProps } from '../templates/page'
import {
	LocationsMapFilterType,
	useLocationsMap,
} from '../hooks/useLocationsMap'

import { Button } from '../components/Button'
import { Icon, IconProps } from '../components/Icon'
import { Text } from '../components/Text'
import { LocationCard } from '../components/LocationCard'
import { Anchor } from '../components/Anchor'
import { Address } from '../components/Address'
import { ButtonLink } from '../components/ButtonLink'
// import { RadioButton } from '../components/RadioButton'
import * as styles from './PageBodyLocations.module.css'
import { Link } from '../components/Link'

const MAP_DEFAULT_CENTER: google.maps.LatLngLiteral = {
	lat: 21.4645213,
	lng: -157.9560242,
}

const MAP_CONTAINER_STYLE: React.CSSProperties = {
	height: '100%',
	width: '100%',
}

const MAP_OPTIONS: google.maps.MapOptions = {
	mapTypeControl: false,
	fullscreenControl: false,
	zoom: 11,
	minZoom: 5,
	maxZoom: 17,
	center: MAP_DEFAULT_CENTER,
}

type LocationMarkerProps = {
	title?: string
	href: string
	label: string
	lat: number
	lng: number
	streetAddress?: string | null
	state?: string | null
	city?: string | null
	zipCode?: string | null
	country?: string | null
	isActive: boolean
	setActive: () => void
}

const LocationMarker = ({
	title,
	href,
	label,
	lat,
	lng,
	streetAddress,
	city,
	state,
	zipCode,
	country,
	isActive,
	setActive,
}: LocationMarkerProps) => (
	<div className={clsx(isActive && 'relative z-10')}>
		<Marker
			label={{
				text: label,
				color: 'white',
				fontSize: '13',
				className: 'font-extrabold',
			}}
			position={{ lat, lng }}
			onClick={() => setActive()}
		/>

		{isActive && (
			<InfoBox
				position={{ lat, lng } as any}
				options={{ closeBoxURL: '', boxClass: 'shadow-md mt-1 ml-1' }}
			>
				<div className="grid gap-4 p-4 border-t-8 bg-yellow-50 text-gray-10 w-12rem border-red-50">
					<Text variant="sans-16" className="font-bold">
						<Anchor
							href={href}
							colorClassName="text-red-50"
							hoverColorClassName="hover:text-teal-40"
							focusColorClassName="focus:text-teal-40"
						>
							{title}
						</Anchor>
					</Text>

					<Text variant="sans-13">
						<Address
							streetAddress={streetAddress}
							city={city}
							state={state}
							zipCode={zipCode}
							country={country}
						/>
					</Text>

					<ButtonLink variant="tealSmall" href={href}>
						View details
					</ButtonLink>
				</div>
			</InfoBox>
		)}
	</div>
)

type FilterCheckboxProps = {
	name: string
	iconName: IconProps['name']
	label: string
	checked?: boolean
	onChange?: React.FormEventHandler<HTMLInputElement>
	type: LocationsMapFilterType
	enableFilter: (type: LocationsMapFilterType) => void
	disableFilter: (type: LocationsMapFilterType) => void
}

const FilterCheckbox = ({
	name,
	iconName,
	label,
	type,
	checked = false,
	enableFilter,
	disableFilter,
}: FilterCheckboxProps) => (
	<li>
		<label className="grid justify-start grid-flow-col gap-3">
			<input
				name={name}
				type="checkbox"
				checked={checked}
				onChange={(event) =>
					event.currentTarget.checked ? enableFilter(type) : disableFilter(type)
				}
				className="w-5 h-5 bg-white border-0 rounded"
			/>
			<div className="grid items-center grid-flow-col gap-2">
				<div className="flex items-center justify-center w-5 h-5">
					<Icon name={iconName} className="w-full h-full" />
				</div>
				<Text as="span" variant="sans-18">
					{label}
				</Text>
			</div>
		</label>
	</li>
)

export type PageBodyLocationsProps = PageTemplateEnhancerProps

const PageBodyLocations = ({
	id,
	previousOverhangs,
	previousIsHeader,
}: PageBodyLocationsProps) => {
	const [mapState, mapDerivedState, mapActions] = useLocationsMap()
	const rSearchQueryInput = React.useRef<HTMLInputElement>(null)

	React.useEffect(() => {
		if (!mapState.map) return

		const params = new URLSearchParams(window.location.search)

		const initial = params.get('default')
		if (initial === 'delivery') {
			return mapActions.enableFilter(LocationsMapFilterType.Delivery)
		} else if (initial === 'pickup') {
			return mapActions.disableFilter(LocationsMapFilterType.Delivery)
		}
	}, [mapState.map])

	React.useEffect(() => {
		if (!mapState.map) return

		const params = new URLSearchParams(window.location.search)

		const searchQ = params.get('search')
		if (searchQ) {
			mapActions.searchAndMoveMap(searchQ)

			if (rSearchQueryInput.current) {
				rSearchQueryInput.current.defaultValue = searchQ
			}
		}
	}, [mapState.map, mapActions])

	return (
		<section
			id={id}
			className={clsx(
				'relative max-w-screen-xl mx-auto bg-white text-gray-10 grid overflow-hidden',
				styles.grid,
			)}
		>
			{/* Find A Restaurant */}
			<form
				action=""
				method="get"
				onSubmit={mapActions.onSearchFormSubmit}
				className={clsx(
					'p-6 md:px-9 md:py-10 bg-yellow-50 text-gray-10',
					'row-start-1',
					'md:col-start-1',
					previousOverhangs && 'pt-11',
					previousIsHeader && 'pt-9',
				)}
			>
				<div className="grid gap-5">
					{/* <fieldset className="flex"> */}
					{/* 	<legend className="sr-only">Select a location type:</legend> */}

					{/* 	<RadioButton */}
					{/* 		className="flex-grow" */}
					{/* 		name="locationType" */}
					{/* 		value="Pickup" */}
					{/* 		label="Pickup" */}
					{/* 		checked={!mapState.filterDelivery} */}
					{/* 		onChange={(e) => { */}
					{/* 			if (!e.target.checked) { */}
					{/* 				return */}
					{/* 			} */}

					{/* 			mapActions.disableFilter(LocationsMapFilterType.Delivery) */}
					{/* 		}} */}
					{/* 	/> */}
					{/* 	<RadioButton */}
					{/* 		className="flex-grow" */}
					{/* 		name="locationType" */}
					{/* 		value="Delivery" */}
					{/* 		label="Delivery" */}
					{/* 		checked={mapState.filterDelivery} */}
					{/* 		onChange={(e) => { */}
					{/* 			if (!e.target.checked) { */}
					{/* 				return */}
					{/* 			} */}

					{/* 			mapActions.enableFilter(LocationsMapFilterType.Delivery) */}
					{/* 		}} */}
					{/* 	/> */}
					{/* </fieldset> */}

					<div className="flex md:flex-col lg:flex-row">
						<label className="flex-grow">
							<span className="sr-only">Search</span>

							<input
								name="searchQuery"
								placeholder="City, State or Zip Code"
								onChange={mapActions.onSearchQueryChanged}
								className="w-full min-w-0 px-4 py-3 font-sans placeholder-gray-60 text-15 md:text-18 text-gray-10"
								ref={rSearchQueryInput}
							/>
						</label>

						<Button
							type="submit"
							minWidthClassName="min-w-0"
							paddingClassName="py-4 px-3 md:px-4"
						>
							Search
						</Button>
					</div>
				</div>

				<Link
					className="flex items-center mt-2 text-red-50 group"
					href="/ll-locations-listing"
				>
					<Text
						variant="sans-15"
						className="font-bold tracking-wide group-hover:underline"
					>
						Locations by state
					</Text>

					<ArrowRightCircleIcon className="w-5 ml-[0.375rem]" />
				</Link>

				<Text
					variant="sans-18"
					className="font-bold tracking-wide text-white uppercase mt-5"
				>
					Filters
				</Text>

				<ul className="grid items-start gap-3 lg:gap-x-6 lg:grid-cols-auto-1fr mt-4">
					<FilterCheckbox
						name="provides_online_ordering"
						iconName="locationCardOnlineOrdering"
						label="Mobile Ordering"
						checked={mapState.filterOnlineOrdering}
						type={LocationsMapFilterType.OnlineOrdering}
						enableFilter={mapActions.enableFilter}
						disableFilter={mapActions.disableFilter}
					/>
					{/* <FilterCheckbox */}
					{/* 	name="accepts_gift_cards" */}
					{/* 	iconName="locationCardGiftCard" */}
					{/* 	label="Gift Cards" */}
					{/* 	checked={mapState.filterGiftCards} */}
					{/* 	type={LocationsMapFilterType.GiftCards} */}
					{/* 	enableFilter={mapActions.enableFilter} */}
					{/* 	disableFilter={mapActions.disableFilter} */}
					{/* /> */}
					{/* <FilterCheckbox
                        name="provides_delivery"
                        iconName="locationCardDelivery"
                        label="Delivery"
                        checked={mapState.filterDelivery}
                        type={LocationsMapFilterType.Delivery}
                        enableFilter={mapActions.enableFilter}
                        disableFilter={mapActions.disableFilter}
                    /> */}
					{/* 
                        <FilterCheckbox
                            name="on_app"
                            iconName="phone"
                            label="On the L&L App"
                            checked={mapState.filterOnApp}
                            type={LocationsMapFilterType.OnApp}
                            enableFilter={mapActions.enableFilter}
                            disableFilter={mapActions.disableFilter}
                        />
                    */}
				</ul>
			</form>

			{/* Location List */}
			<div className="row-start-3 md:row-start-2 md:col-start-1">
				{mapDerivedState.scopedLocationFeaturesOnMap.length > 0 ? (
					<ul className="grid gap-px bg-gray-80 md:h-36rem lg:h-46rem md:overflow-auto content-start">
						{mapDerivedState.scopedLocationFeaturesOnMap.map(
							(locationFeature, i) => (
								<li
									key={locationFeature.properties.href}
									className={clsx(
										'transition',
										mapState.selectedLocationUID ===
											locationFeature.properties.uid && 'ring-4',
									)}
								>
									<LocationCard
										mapLabel={(i + 1).toString()}
										type={locationFeature.properties.type}
										href={locationFeature.properties.href}
										title={locationFeature.properties.title}
										streetAddress={locationFeature.properties.streetAddress}
										city={locationFeature.properties.city}
										state={locationFeature.properties.state}
										zipCode={locationFeature.properties.zipCode}
										country={locationFeature.properties.country}
										loyaltyplantHref={
											locationFeature.properties.loyaltyplantHref
										}
										orderType={mapState.filterDelivery ? 'Delivery' : 'Pickup'}
										ubereatsHref={locationFeature.properties.ubereatsHref}
										doordashHref={locationFeature.properties.doordashHref}
										postmatesHref={locationFeature.properties.postmatesHref}
										biteSquadHref={locationFeature.properties.biteSquadHref}
										grubhubHref={locationFeature.properties.grubhubHref}
										acceptsGiftCards={
											locationFeature.properties.acceptsGiftCards
										}
										providesOnlineOrdering={
											locationFeature.properties.providesOnlineOrdering
										}
										providesDelivery={
											locationFeature.properties.providesDelivery
										}
										className="h-full"
									/>
								</li>
							),
						)}
					</ul>
				) : mapState.isReady ? (
					<div className="grid content-center gap-6 p-6 md:h-36rem md:overflow-auto">
						<Text
							variant="sans-24-30"
							className="font-extrabold text-center text-red-50"
						>
							Sorry!
						</Text>

						<Text variant="sans-15" className="text-center">
							No locations found. Try a different area or change your filters.
						</Text>
					</div>
				) : (
					<div className="grid content-center gap-7 md:gap-8 p-6 md:h-36rem md:overflow-auto">
						<Text variant="sans-24-30" className="text-center text-gray-60">
							Loading…
						</Text>
					</div>
				)}
			</div>

			{/* Map */}
			<div className="bg-gray-60 aspect-w-4 aspect-h-3 md:aspect-none row-start-2 md:col-start-2 md:row-start-1 md:row-span-2">
				<div className="flex md:h-full">
					<div className="flex-grow">
						{mapState.isReady ? (
							<GoogleMap
								mapContainerStyle={MAP_CONTAINER_STYLE}
								options={MAP_OPTIONS}
								onLoad={mapActions.onMapLoad}
								onUnmount={mapActions.onMapUnmount}
								onBoundsChanged={mapActions.onBoundsChanged}
								onDragStart={mapActions.deselectLocation}
								onClick={mapActions.deselectLocation}
							>
								{mapDerivedState.scopedLocationFeaturesOnMap.map(
									(locationFeature, i) => (
										<LocationMarker
											key={locationFeature.properties.href}
											label={(i + 1).toString()}
											title={locationFeature.properties.title}
											href={locationFeature.properties.href}
											lat={locationFeature.geometry.coordinates[1]}
											lng={locationFeature.geometry.coordinates[0]}
											streetAddress={locationFeature.properties.streetAddress}
											city={locationFeature.properties.city}
											state={locationFeature.properties.state}
											zipCode={locationFeature.properties.zipCode}
											country={locationFeature.properties.country}
											isActive={
												mapState.selectedLocationUID ===
												locationFeature.properties.uid
											}
											setActive={() =>
												mapActions.selectLocation(
													locationFeature.properties.uid,
												)
											}
										/>
									),
								)}
							</GoogleMap>
						) : (
							'Loading'
						)}
					</div>
				</div>
			</div>
		</section>
	)
}

export const mapDataToContext = () => ({
	bg: 'bg-white',
})

export default React.memo(PageBodyLocations)
