import * as React from 'react'
import { graphql } from 'gatsby'
import * as ReachTabs from '@reach/tabs'
import clsx from 'clsx'

import { MapDataToPropsArgs } from '../lib/mapSlicesToComponents'
import { PageTemplateEnhancerProps } from '../templates/page'

import { BoundedBox } from '../components/BoundedBox'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import { VideoPlayer } from '../components/VideoPlayer'

type VideoPanelProps = {
	index: number
	videoUrl?: string
	posterImage?: IGatsbyImageData
	posterImageAlt?: string
}

const VideoTabPanel = ({
	index,
	videoUrl,
	posterImage,
	posterImageAlt,
}: VideoPanelProps) => {
	const { selectedIndex } = ReachTabs.useTabsContext()
	const isSelected = index === selectedIndex

	const [isPlaying, setPlaying] = React.useState(false)

	React.useEffect(() => {
		if (!isSelected) {
			setPlaying(false)
		}
	}, [isSelected])

	return (
		<VideoPlayer
			url={videoUrl}
			isPlaying={isPlaying}
			setPlaying={setPlaying}
			posterImage={posterImage}
			posterImageAlt={posterImageAlt}
			className="aspect-w-16 aspect-h-9"
		/>
	)
}

type VideoTabProps = {
	index: number
	posterImage?: IGatsbyImageData
	posterImageAlt?: string
}

const VideoTab = ({ index, posterImage, posterImageAlt }: VideoTabProps) => {
	const { selectedIndex } = ReachTabs.useTabsContext()
	const isSelected = index === selectedIndex

	return (
		<div className="relative">
			<div
				className={clsx(
					'aspect-w-16 aspect-h-9 border-2 md:border-4 transition',
					isSelected ? 'border-red-50' : 'border-transparent',
				)}
			>
				<div>
					{posterImage && (
						<GatsbyImage
							image={posterImage}
							alt={posterImageAlt ?? ''}
							className="h-full"
						/>
					)}
				</div>
			</div>
		</div>
	)
}

export type PageBodyVideosProps = ReturnType<typeof mapDataToProps> &
	PageTemplateEnhancerProps

const PageBodyVideos = ({
	children,
	id,
	nextSharesBg,
	nextOverhangs,
	previousOverhangs,
	nextIsFooter,
}: PageBodyVideosProps) => {
	const hasMultipleVideos = React.Children.count(children) > 1

	return (
		<BoundedBox
			as="section"
			id={id}
			nextSharesBg={nextSharesBg}
			nextOverhangs={nextOverhangs}
			previousOverhangs={previousOverhangs}
			innerMaxWidthClassName="max-w-70rem"
			ptClassName="pt-0"
			pbClassName={nextIsFooter ? undefined : 'pb-0'}
			className="mx-auto bg-white max-w-screen-xl"
		>
			<div className="flex flex-col">
				<div
					className={clsx(
						'-mx-6 bg-white md:-mx-0 md:shadow-xl lg:shadow-2xl md:relative md:z-10 md:-mt-9 lg:-mt-12',
						!nextIsFooter && 'md:-mb-9 lg:-mb-12',
					)}
				>
					<ReachTabs.Tabs>
						<ReachTabs.TabPanels>
							{React.Children.map(children, (child, index) => {
								if (!React.isValidElement(child)) {
									return null
								}

								const props = child.props as PageBodyVideosVideoProps

								return (
									<ReachTabs.TabPanel>
										<VideoTabPanel
											key={props.name}
											index={index}
											videoUrl={props.videoUrl ?? undefined}
											posterImage={props.posterImage}
											posterImageAlt={props.posterImageAlt ?? undefined}
										/>
									</ReachTabs.TabPanel>
								)
							})}
						</ReachTabs.TabPanels>

						{hasMultipleVideos && (
							<BoundedBox
								ptClassName="pt-6 md:pt-8"
								pbClassName="pb-6 md:pb-8"
								plClassName="pl-6 md:pl-8"
								prClassName="pr-6 md:pr-8"
								className="bg-gray-10"
							>
								<ReachTabs.TabList className="flex flex-wrap justify-center -mt-4 -ml-4 md:-mt-6 md:-ml-6">
									{React.Children.map(children, (child, index) => {
										if (!React.isValidElement(child)) {
											return null
										}

										const props = child.props as PageBodyVideosVideoProps

										return (
											<div
												key={props.videoUrl}
												className="w-1/3 pt-4 pl-4 md:w-1/4 md:pt-6 md:pl-6"
											>
												<ReachTabs.Tab className="block w-full">
													<span className="sr-only">{props.videoTitle}</span>

													<VideoTab
														index={index}
														posterImage={props.posterImage}
														posterImageAlt={props.posterImageAlt ?? undefined}
													/>
												</ReachTabs.Tab>
											</div>
										)
									})}
								</ReachTabs.TabList>
							</BoundedBox>
						)}
					</ReachTabs.Tabs>
				</div>
			</div>
		</BoundedBox>
	)
}

type PageBodyVideosVideoProps = {
	name?: string | null
	videoUrl?: string | null
	videoTitle?: string | null
	posterImage?: IGatsbyImageData
	posterImageAlt?: string | null
}

const PageBodyVideosVideo = (_props: PageBodyVideosVideoProps) => null
PageBodyVideos.Video = PageBodyVideosVideo

export const mapDataToProps = ({
	data,
}: MapDataToPropsArgs<
	Queries.PageBodyVideosFragment,
	typeof mapDataToContext
>) => ({
	children: data.items?.map?.((item) => (
		<PageBodyVideos.Video
			key={item?.video_url?.embed_url}
			videoUrl={item?.video_url?.embed_url}
			videoTitle={item?.video_url?.title}
			posterImage={getImage(item?.poster_image)}
			posterImageAlt={item?.poster_image?.alt}
		/>
	)),
})

export const mapDataToContext = () => ({
	bg: [Symbol(''), 'bg-white'],
	overhangsBottom: [false, true],
	overhangsTop: [false, true],
})

export const fragment = graphql`
	fragment PageBodyVideos on PrismicPageDataBodyVideos {
		items {
			video_url {
				embed_url
				title
			}
			poster_image {
				alt
				gatsbyImageData(
					width: 1000
					layout: FULL_WIDTH
					placeholder: BLURRED
					sizes: "100vw"
				)
			}
		}
	}
`

export default PageBodyVideos
